<template>
  <b-card>

    <b-row>
      <b-button
        v-permission="'viewIncomeTransactions'"
        class="btn-filter"
        variant="adn"
        @click=" () => {
          showFilter = !showFilter;
          ResetSelection();
        }"
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="danger"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilter"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="transactionNumber"
          v-silent-search
          :value.sync="filter.transactionNumber"
          label-text="transactionNumber"
          name="transactionNumber"
          @keydown.enter="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('fromDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.transactionDateFrom"
          locale="ar"
          :date-format-options=" {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="transactionDateFrom"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('toDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.transactionDateTo"
          locale="ar"
          :date-format-options=" {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="transactionDateTo"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.transactionId"
          :dir="isRight ? 'rtl' : 'ltr'"
          :label-text="pathName === 'income-transactions' ? 'income' : 'expense'"
          field="select"
          :options="transactions"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.paymentWay"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="paymentMethod"
          field="select"
          :options="paymentWays"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("accountsPostSituation") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', {btnActive: postGroupName === 'all'}]"
            variant="flat-warning"
            @click="() => {
              postGroupName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', {btnActive: postGroupName === 'posted'}]"
            variant="flat-warning"
            @click="() => {
              postGroupName = 'posted'
              refreshItems()
            }"
          >
            {{ $t("posted") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', {btnActive: postGroupName === 'unPosted'}]"
            variant="flat-warning"
            @click="() => {
              postGroupName = 'unPosted'
              refreshItems()
            }"
          >
            {{ $t("unPosted") }}
          </b-button>
        </b-button-group>
      </b-col>

      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("sourceSituationFromTax", { source: this.$route.name === 'expense-transactions' ? $t('expenses') : $t('incomes') }) }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', {btnActive: taxGroupName === 'all'}]"
            variant="flat-warning"
            @click="() => {
              taxGroupName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', {btnActive: taxGroupName === 'taxable'}]"
            variant="flat-warning"
            @click="() => {
              taxGroupName = 'taxable'
              refreshItems()
            }"
          >
            {{ $t("taxable") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', {btnActive: taxGroupName === 'nonTaxable'}]"
            variant="flat-warning"
            @click="() => {
              taxGroupName = 'nonTaxable'
              refreshItems()
            }"
          >
            {{ $t("nonTaxable") }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <div>
      <div class="row justify-content-between mt-2">
        <div
          class="d-flex align-items-center justify-content-end col-md-12 col-12 mb-1"
        >
          <b-button
            v-if="this.$route.name === 'income-transactions'"
            v-permission="'addIncomeTransactions'"
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                if (this.$route.name === 'income-transactions') {
                  this.$router.push({ name: 'income-transactions-new' });
                } else {
                  this.$router.push({ name: 'expense-transactions-new' });
                }
              }
            "
          >
            {{ $t("new") }}
          </b-button>
          <b-button
            v-if="this.$route.name === 'expense-transactions'"
            v-permission="'addExpenseTransactions'"
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                if (this.$route.name === 'income-transactions') {
                  this.$router.push({ name: 'income-transactions-new' });
                } else {
                  this.$router.push({ name: 'expense-transactions-new' });
                }
              }
            "
          >
            {{ $t("new") }}
          </b-button>

          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 btn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px; width: auto"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 btn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px; width: auto"
            >
          </b-button>
        </div>
      </div>
    </div>

    <g-table
      ref="transaction-table"
      :items="itemsProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template #isReviewed="{ item }">
        <span>
          <b-form-group>
            <b-form-checkbox
              v-model="item.isReviewed"
              class="mr-0 mt-50"
              inline
              @change="
                (v) => {
                  isReviewed(item);
                }
              "
            />
          </b-form-group>
        </span>
      </template>
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            data-action-type="edit"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="
              () => {
                edit(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-permission="'viewIncomeTransactions'"
            v-b-tooltip.hover.top="item.attachmentUrl !== null
              ? $t('hasAttachMents')
              : $t('hasNotAttachMents')
            "
            data-action-type="download"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              if (item.attachmentUrl !== null) {
                downloadItem(item);
              }
            }
            "
          >
            <feather-icon
              :icon="item.attachmentUrl !== null ? 'FolderPlusIcon' : 'FolderIcon'"
              stroke="orange"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                remove(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from "@/pages/Shared/Table.vue";
import { paymentWays } from "@/libs/acl/Lookups";
import reportMixin from "@/mixin/reportMixin";

export default {
  components: {
    GTable,
  },

  mixins: [reportMixin],

  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      items: [],
      suppliers: [],
      paymentWays: paymentWays,
      pathName: this.$route.name,
      totalVals: 0,
      Agents: [],
      itemsArray: [],
      hideActions: false,

      showFilter: false,
      postGroupName: 'all',
      taxGroupName: 'all',
      fiscalYearStart: null,
      fiscalYearEnd: null,

      filter: {
        transactionNumber: null,
        transactionDateFrom: null,
        transactionDateTo: null,
        transactionId: null,
        paymentWay: null,
        isPosted: null,
        isTaxable: null
      },

      transactions: [],
      paymentMethods: []
    };
  },

  computed: {
    tableColumns() {
      return [
        {
          key: "code",
          field: "code",
          label: this.$t("code"),
          sortable: true,
        },
        {
          key: "transactionDate",
          field: "transactionDate",
          label: this.$t("date"),
          sortable: true,
        },
        {
          key: this.$route.name === 'income-transactions' ? 'incomeArabicName' : 'expenseArabicName',
          field: this.$route.name === 'income-transactions' ? 'incomeArabicName' : 'expenseArabicName',
          label: this.$route.name === 'income-transactions' ? this.$t('incomeName') : this.$t('expenseName'),
          sortable: false,
        },
        {
          key: "paymentMethodName",
          field: "paymentMethodName",
          label: this.$t("Payment Method"),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.paymentMethod === "cash")
              return `<span class="badge badge-light-success p-50">${this.$t(
                "cash"
              )}</span>`;
            if (item.paymentMethod === "visa")
              return `<span class="badge badge-light-primary p-50">${this.$t(
                "visa"
              )}</span>`;
            if (item.paymentMethod === "transfer")
              return `<span class="badge badge-light-info p-50">${this.$t(
                "transfer"
              )}</span>`;
            if (item.paymentMethod === "cheque")
              return `<span class="badge badge-light-warning p-50">${this.$t(
                "cheque"
              )}</span>`;
            if (item.paymentMethod === "other")
              return `<span class="badge badge-light-primary p-50">${this.$t(
                "other"
              )}</span>`;
          },
        },
        {
          key: "value",
          field: "value",
          label: this.$t("value"),
          sortable: true,
          type: "number",
          footer: () => this.fraction(this.totalVals),
        },
        {
          key: 'updatedStatus',
          field: 'updatedStatus',
          label: this.$t('isPosted'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.isPosted) return `<span class="badge bg-secondary bg-darken-1 p-50">${this.$t('posted')}</span>`
            return `<span class="badge border-0 bg-danger bg-darken-1 p-50">${this.$t('unPosted')}</span>`;
          },
        },
        {
          key: "notes",
          field: "notes",
          label: this.$t("notes"),
          sortable: false,
        },
        { key: "actions" },
      ];
    },
  },
  watch: {
    "$route.name": "refreshItems",
    'filter.transactionDateFrom'(newVal) {
      if (newVal > this.filter.transactionDateTo) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },
    'filter.transactionDateTo'(newVal) {
      if (this.filter.transactionDateFrom > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    const taxable = require('@/assets/images/icons/valid.png');
    const nonTaxable = require('@/assets/images/icons/nonValid.png');
    this.tableColumns.splice(4, 0, {
        key: 'taxesSituation',
        kefieldy: 'taxesSituation',
        label: this.$t('isTaxable'),
        sortable: true,
        formatter: (key, value, item) => {
          if (item.isTaxable) return `<span class=""><img src="${taxable}" width="25" alt="isTaxable"></span>`;
          return `<span class=""><img src="${nonTaxable}" width="25" alt="notTaxable"></span>`;
        },
    })
    if (this.$route.name === 'expense-transactions') {
      this.tableColumns.splice(4, 0, {
          key: 'taxesSituation',
          keyfield: 'taxesSituation',
          label: this.$t('isTaxable'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.isTaxable) return `<span class=""><img src="${taxable}" width="25" alt="isTaxable"></span>`;
            return `<span class=""><img src="${nonTaxable}" width="25" alt="notTaxable"></span>`;
          },
      })
    }
    this.transactionType = this.pathName === 'income-transactions'
                         ? "IncomeTransactions"
                         : "ExpensesTransactions"
    this.filter.transactionDateFrom = this.getDate(this.fiscalYearStart)
    this.filter.transactionDateTo = this.getDate(this.fiscalYearEnd)
    this.loadData();
  },

  methods: {
    pdfExport(name) {
      html2canvas(document.getElementById("mainTable")).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },

    printDocument() {
      this.hideActions = true;
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    refreshItems() {
      this.$refs["transaction-table"].refreshTable();
    },

    ResetSelection() {
      if (!this.showFilter) {
        this.filter.transactionDateFrom = this.fiscalYearStart;
        this.filter.transactionDateTo = this.fiscalYearEnd;
        this.filter.transactionNumber = null;
        this.filter.transactionId = null;
        this.filter.paymentWay = null;
        this.postGroupName = 'all';
        this.taxGroupName = 'all';
        this.refreshItems();
      }
    },

    loadData() {
      if(this.pathName === 'income-transactions') {
        this.get({ url: 'incomes' }).then((data) => {
          this.transactions = data;
        });
      } else {
        this.get({ url: 'expenses' }).then((data) => {
          this.transactions = data;
        });
      }
    },

    setFilertForPost() {
      switch (this.postGroupName) {
          case 'posted':
            this.filter.isPosted = true;
            break;

          case 'unPosted':
            this.filter.isPosted = false;
            break;

          default:
            this.filter.isPosted = null;
            break;
        }
    },

    setFilterForTax() {
      switch (this.taxGroupName) {
          case 'taxable':
            this.filter.isTaxable = true;
            break;

          case 'nonTaxable':
            this.filter.isTaxable = false;
            break;

          default:
            this.filter.isTaxable = null;
            break;
        }
    },

    itemsProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.orderClause = this.orderQuery(sortBy || 'Id', sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      this.setFilertForPost();
      this.setFilterForTax();

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;

      this.get({ url: `${this.transactionType}${params}`})
        .then(( data ) => {
          this.totalVals = 0;
          data.pageData.forEach((item) => {
            this.totalVals += item.value;
            item.transactionDate = this.getDate(item.transactionDate);
        });
          this.isTableBusy = false;
          this.totalRows = data.totalRecords;
          callback(data.pageData);

          // then set items for excel and pdf
          this.itemsArray = data.pageData;
          this.itemsArray.forEach(element => {
            element.updatedStatus = element.isPosted ? this.$t('posted') : this.$t('unPosted');
            element.paymentMethodName = element.paymentMethod === 'cash' ? this.$t('cash') : this.$t('bankAccount');
            element.taxesSituation = element.isTaxable ? this.$t('yes') : this.$t('no');
          });
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    remove(item) {
      this.confirmAction(
        {
          text: this.$t("areYouSureYouWantToDelete"),
        },
        () => {
          if (this.$route.name === "income-transactions") {
            this.delete({
              url: "IncomeTransactions",
              id: item.id,
            }).then(() => {
              this.doneAlert({ text: this.$t("deletedSuccessfully") });
              this.refreshItems();
            });
          } else {
            this.delete({
              url: "ExpensesTransactions",
              id: item.id,
            }).then(() => {
              this.doneAlert({ text: this.$t("deletedSuccessfully") });
              this.refreshItems();
            });
          }
        }
      );
    },

    edit(item) {
      if (this.$route.name === "income-transactions") {
        this.$router.push({
          name: "income-transactions-edit",
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: "expense-transactions-edit",
          params: { id: item.id },
        });
      }
    },

    print(item) {
      const reportName = this.isRight ? 'ExpenseAndIncomeTransaction-ar' : 'ExpenseAndIncomeTransaction-en';
      const printedItem = {
        id: item.id,
        transactionType: this.$route.name === 'income-transactions' ? 0 : 1
      }
      this.printReport(reportName, printedItem);
    },

    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    }
  },
};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border: 2px solid #ad8884;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: -3px;
}

.gb-package label {
  font-size: 14px;
  margin-bottom: 10px;
}
b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2)
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
